import React, { useState } from 'react';
//MaterialUI Imports
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import MoneyIcon from '@mui/icons-material/Money';

//Third Party Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import useTMDailyAllowanceService from 'services/useTMDailyAllowanceService';

const TMDailyAllowance = (props) => {
    //Constants
    const { onSave, onCancel, selectedTMDAs } = props;
    const { addTMDailyAllowance, updateTMDailyAllowance } = useTMDailyAllowanceService();

    //States
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: selectedTMDAs.id,
            tmSalarySlabId: 1,
            unitMin: selectedTMDAs.unitMin,
            unitMax: selectedTMDAs.unitMax,
            amount: selectedTMDAs.amount,
            campToCamp: selectedTMDAs.campToCamp
        },
        validationSchema: Yup.object({
            unitMin: Yup.number(),
            unitMax: Yup.number(),
            amount: Yup.number(),
            campToCamp: Yup.number()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedTMDAs.id != 0) {
                let data = {
                    tmSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    amount: values.amount,
                    campToCamp: values.campToCamp
                };
                updateTMDailyAllowance(selectedTMDAs.id, data)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                let data = {
                    tmSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    amount: values.amount,
                    campToCamp: values.campToCamp
                };
                addTMDailyAllowance(data)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedTMDAs.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <MoneyIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New TM Daily Allowance</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <MoneyIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit TM Daily Allowance Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="unitMin"
                                label="Unit Min"
                                onChange={formik.handleChange}
                                value={formik.values.unitMin}
                                fullWidth
                                error={formik.touched.unitMin && Boolean(formik.errors.unitMin)}
                                helperText={formik.touched.unitMin && formik.errors.unitMin}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="unitMax"
                                label="Unit Max"
                                onChange={formik.handleChange}
                                value={formik.values.unitMax}
                                fullWidth
                                error={formik.touched.unitMax && Boolean(formik.errors.unitMax)}
                                helperText={formik.touched.unitMax && formik.errors.unitMax}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="amount"
                                label="Amount"
                                onChange={formik.handleChange}
                                value={formik.values.amount}
                                fullWidth
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="campToCamp"
                                label="CampToCamp"
                                onChange={formik.handleChange}
                                value={formik.values.campToCamp}
                                fullWidth
                                error={formik.touched.campToCamp && Boolean(formik.errors.campToCamp)}
                                helperText={formik.touched.campToCamp && formik.errors.campToCamp}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default TMDailyAllowance;
