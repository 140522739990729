import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useTMSalarySlabsService = () => {
    const getTMSalarySlab = async () => {
        try {
            const response = await axiosAuth.get(`/api/TMSalarySlabs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getTMSalarySlabById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/TMSalarySlabs/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateTMSalarySlab = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/TMSalarySlabs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getTMSalarySlab, getTMSalarySlabById, updateTMSalarySlab };
};

export default useTMSalarySlabsService;
