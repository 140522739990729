import React, { useEffect, useState } from 'react';
//Material Imports
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import PaymentsIcon from '@mui/icons-material/Payments';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import NoResults from 'assets/images/noresults.png';
import GCGroupIncentive from './GCGroupIncentive';
import GCSalarySlabItems from './GCSalarySlabItems';
import useGCSalarySlabsService from 'services/useGCSalarySlabsService';
import useGCGroupIncentivesService from 'services/useGCGroupIncentivesService';
import useGCSalarySlabItemsService from 'services/useGCSalarySlabItemsService';

const GCSalarySlab = () => {
    //Constants
    const theme = useTheme();
    const { getGCSalarySlab, updateGCSalarySlab } = useGCSalarySlabsService();
    const { getGCGroupIncentives, softDeleteGCGroupIncentives } = useGCGroupIncentivesService();
    const { getGCSalarySlabItems, softDeleteGCSalarySlabItems } = useGCSalarySlabItemsService();

    //States
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingGCSalarySlab, setLoadingGCSalarySlab] = useState(false);
    const [gcSalarySlabDetails, setGCSalarySlabDetails] = useState([]);
    const [gcSalarySlabItemsDetails, setGCSalarySlabItemsDetails] = useState([]);
    const [gcGroupIncentiveDetails, setGCGroupIncentiveDetails] = useState([]);

    const [selectedGCSalarySlabItems, setSelectedGCSalarySlabItems] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);

    const [selectedGCGroupIncentive, setSelectedGCGroupIncentive] = useState('');
    const [openFormGCGroupIncentive, setOpenFormGCGroupIncentive] = useState(false);
    const [openGCGroupIncentiveDeleteDialog, setOpenGCGroupIncentiveDeleteDialog] = useState(false);
    const [openGCGroupIncentiveToast, setGCGroupIncentiveOpenToast] = useState(false);

    const [gcFixedSalary, setGCFixedSalary] = useState('');

    const [gcSalarySlabId, setGCSalarySlabId] = useState(0);

    // Methods

    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getGCSalarySlabItemsList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const handleClose = () => {
        setOpenFormGCGroupIncentive(false);
    };

    const handleSaveGCGroupIncentive = () => {
        getGCGroupIncentiveList();
    };

    const handleCloseGCGroupIncentiveToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setGCGroupIncentiveOpenToast(false);
    };
    //GC Salary Slab
    const getGCSalarySlabList = () => {
        setGCSalarySlabDetails(undefined);
        getGCSalarySlab()
            .then((response) => {
                console.log(response);
                setGCSalarySlabDetails(response.data.list);
                setGCFixedSalary(response.data.list[0].fixedSalary);
                setGCSalarySlabId(response.data.list[0].id);
            })
            .catch((error) => setIsError(error.message));
    };
    //GC Salary Slab Item
    const getGCSalarySlabItemsList = () => {
        setGCSalarySlabItemsDetails(undefined);
        getGCSalarySlabItems(gcSalarySlabId)
            .then((response) => {
                console.log(response);
                setGCSalarySlabItemsDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleDelete = () => {
        setLoading(true);
        softDeleteGCSalarySlabItems(selectedGCSalarySlabItems.id)
            .then((response) => {
                getGCSalarySlabItemsList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };
    //GC Group Incentive
    const getGCGroupIncentiveList = () => {
        setGCGroupIncentiveDetails(undefined);
        getGCGroupIncentives(gcSalarySlabId)
            .then((response) => {
                console.log(response);
                setGCGroupIncentiveDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleGCGroupIncentiveDelete = () => {
        setLoading(true);
        softDeleteGCGroupIncentives(selectedGCGroupIncentive.id)
            .then((response) => {
                getGCGroupIncentiveList();
                setGCGroupIncentiveOpenToast(true);
                setOpenGCGroupIncentiveDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenGCGroupIncentiveDeleteDialog(false);
            });
    };

    //Update GC Salary
    const handleUpdate = () => {
        setLoadingGCSalarySlab(true);
        let data = {
            fixedSalary: gcFixedSalary
        };
        updateGCSalarySlab(gcSalarySlabDetails[0].id, data).then((response) => {
            setLoadingGCSalarySlab(false);
            console.log('UPDATE CALL', response);
        });
    };

    //UseEffects
    useEffect(() => {
        getGCSalarySlabList();
        // getGCSalarySlabItemsList();
        // getGCGroupIncentiveList();
    }, []);
    useEffect(() => {
        getGCSalarySlabItemsList();
    }, [gcSalarySlabId]);
    useEffect(() => {
        getGCGroupIncentiveList();
    }, [gcSalarySlabId]);
    return (
        <>
            <MainCard>
                <CardHeader title="GC Salary Slabs" avatar={<PaymentsIcon color="primary" />} sx={{ padding: 0 }} />
                <Divider sx={{ marginY: 3 }} />
                <Grid container spacing={2} direction="row" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Grid item sm={2} xs={12}>
                        <Typography variant="h5">Fixed Salary :</Typography>
                    </Grid>
                    {gcFixedSalary != undefined || gcFixedSalary != null ? (
                        <Grid item sm={4} xs={12}>
                            <TextField
                                id="Fixed Salary"
                                type="number"
                                name="gcFixedSalary"
                                label="Fixed Salary"
                                variant="outlined"
                                value={gcFixedSalary}
                                fullWidth
                                onChange={(event) => {
                                    setGCFixedSalary(event.target.value);
                                    //console.log(abmFixeSalary);
                                }}
                            ></TextField>
                        </Grid>
                    ) : null}

                    <Grid item sm={4} xs={12}>
                        <LoadingButton
                            loading={loadingGCSalarySlab}
                            onClick={() => {
                                handleUpdate();
                            }}
                            variant="contained"
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 2 }} />
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">GC Field Expenses</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedGCSalarySlabItems({
                                    id: 0,
                                    gcSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    fielsExpenses: 0,
                                    pri: 0,
                                    da: 0,
                                    ta: 0,
                                    incentive: 0
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {gcSalarySlabItemsDetails != undefined || gcSalarySlabItemsDetails != null ? (
                            <>
                                {gcSalarySlabItemsDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no GC salary slab item details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 70 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Units</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 180 }}>
                                                            <Typography variant="h5">Field Expenses</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 180 }}>
                                                            <Typography variant="h5">PRI</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 130 }}>
                                                            <Typography variant="h5">D.A / Day</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 130 }}>
                                                            <Typography variant="h5">T.A / Day</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 130 }}>
                                                            <Typography variant="h5">Incentive</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 120 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {gcSalarySlabItemsDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>
                                                                <TableCell align="left">{row.fieldExpenses}</TableCell>
                                                                <TableCell align="left">{row.pri}</TableCell>
                                                                <TableCell align="left">{row.da}</TableCell>
                                                                <TableCell align="left">{row.ta}</TableCell>
                                                                <TableCell align="left">{row.incentive}</TableCell>
                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit GC salary slab item details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGCSalarySlabItems(row);
                                                                                setOpenForm(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete GC salary slab item">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGCSalarySlabItems(row);
                                                                                setOpenDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {gcSalarySlabItemsDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Field Expenses:</Typography>
                                                        <Typography>{row.fielsExpenses}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Field Expenses:</Typography>
                                                        <Typography>{row.fielsExpenses}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">PRI:</Typography>
                                                        <Typography>{row.pri}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">D.A:</Typography>
                                                        <Typography>{row.da}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">T.A:</Typography>
                                                        <Typography>{row.ta}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Incentive:</Typography>
                                                        <Typography>{row.incentive}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit GC salary slab item details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGCSalarySlabItems(row);
                                                                    setOpenForm(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete GC salary slab item">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGCSalarySlabItems(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 2 }} />

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5"> GC Group Incentive</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedGCGroupIncentive({
                                    id: 0,
                                    gcalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    amount: 0
                                });
                                setOpenFormGCGroupIncentive(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {gcGroupIncentiveDetails != undefined || gcGroupIncentiveDetails != null ? (
                            <>
                                {gcGroupIncentiveDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There is no GC group incentive details found...
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer sx={{ maxWidth: 500 }}>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Unit</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 100 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 110 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {gcGroupIncentiveDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>
                                                                <TableCell align="left">{row.amount}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit GC group incentive details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGCGroupIncentive(row);
                                                                                setOpenFormGCGroupIncentive(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete GC group incentive">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedGCGroupIncentive(row);
                                                                                setOpenGCGroupIncentiveDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {gcGroupIncentiveDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Amount:</Typography>
                                                        <Typography>{row.amount}</Typography>
                                                    </Stack>

                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit GC group incentive details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGCGroupIncentive(row);
                                                                    setOpenFormGCGroupIncentive(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete GC group incentive">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedGCGroupIncentive(row);
                                                                    setOpenGCGroupIncentiveDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* }
                /> */}
            </MainCard>

            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <GCSalarySlabItems onSave={handleSave} onCancel={handleCancel} selectedGCSalarySlabItems={selectedGCSalarySlabItems} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete GC Salary Slab Item Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this GC salary slab item details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> GC salary slab item deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>

            {/* ---------------GC Group Incentive----------------------------- */}
            <Dialog open={openFormGCGroupIncentive} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogContent>
                    <GCGroupIncentive
                        onSave={handleSaveGCGroupIncentive}
                        onCancel={handleClose}
                        selectedGCGroupIncentive={selectedGCGroupIncentive}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openGCGroupIncentiveDeleteDialog}
                onClose={() => {
                    setOpenGCGroupIncentiveDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete GC Group Incentive Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this GC group incentive details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleGCGroupIncentiveDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenGCGroupIncentiveDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openGCGroupIncentiveToast} autoHideDuration={6000} onClose={handleCloseGCGroupIncentiveToast}>
                    <Alert
                        onClose={handleCloseGCGroupIncentiveToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> GC group incentive deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default GCSalarySlab;
