import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useAmountTransactionService = () => {
    const getAmountTransactionFilter = async (senderId, receiverId, fromDate, toDate, status) => {
        try {
            const response = await axiosAuth.get(
                `/api/AmountTransaction/Filtered?senderid=${senderId}&receiverid=${receiverId}&fromdate=${fromDate}&todate=${toDate}&status=${status}`
            );
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getAmountTransaction = async () => {
        try {
            const response = await axiosAuth.get(`/api/AmountTransaction`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addAmountTransaction = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/AmountTransaction`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateAmountTransaction = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/AmountTransaction?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const deleteAmountTransaction = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/AmountTransaction?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateAmountTransactionStatus = async (id, status) => {
        try {
            const response = await axiosAuth.put(`/api/AmountTransaction/update-status?id=${id}&status=${status}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getAmountTransactionPendingAmount = async (userId, month, year) => {
        try {
            const response = await axiosAuth.get(`/api/AmountTransaction/amount-pending?userid=${userId}&month=${month}&year=${year}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        getAmountTransactionFilter,
        getAmountTransaction,
        addAmountTransaction,
        updateAmountTransaction,
        updateAmountTransactionStatus,
        deleteAmountTransaction,
        getAmountTransactionPendingAmount
    };
};

export default useAmountTransactionService;
