import React, { useState } from 'react';
//MaterialUI Imports
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import MoneyIcon from '@mui/icons-material/Money';

//Third Party Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports

import useBM6FieldExpenses from 'services/useBM6FieldExpensesService';

const BM6FieldExpenses = (props) => {
    //Constants
    const { onSave, onCancel, selectedBM6FieldExpenses } = props;
    const { addBM6FieldExpenses, updateBM6FieldExpenses } = useBM6FieldExpenses();

    //States
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: selectedBM6FieldExpenses.id,
            bM6SalarySlabId: 1,
            unitMin: selectedBM6FieldExpenses.unitMin,
            unitMax: selectedBM6FieldExpenses.unitMax,
            amount: selectedBM6FieldExpenses.amount,
            incentive: selectedBM6FieldExpenses.incentive
        },
        validationSchema: Yup.object({
            unitMin: Yup.number(),
            unitMax: Yup.number(),
            amount: Yup.number(),
            incentive: Yup.number()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedBM6FieldExpenses.id != 0) {
                let data = {
                    bM6SalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    amount: values.amount,
                    incentive: values.incentive
                };
                updateBM6FieldExpenses(selectedBM6FieldExpenses.id, data)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                let data = {
                    bm6SalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    amount: values.amount,
                    incentive: values.incentive
                };
                addBM6FieldExpenses(data)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedBM6FieldExpenses.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <MoneyIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New BM-6 Field Expenses</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <MoneyIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit BM-6 Field Expenses Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="unitMin"
                                label="Unit Min"
                                onChange={formik.handleChange}
                                value={formik.values.unitMin}
                                fullWidth
                                error={formik.touched.unitMin && Boolean(formik.errors.unitMin)}
                                helperText={formik.touched.unitMin && formik.errors.unitMin}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="unitMax"
                                label="Unit Max"
                                onChange={formik.handleChange}
                                value={formik.values.unitMax}
                                fullWidth
                                error={formik.touched.unitMax && Boolean(formik.errors.unitMax)}
                                helperText={formik.touched.unitMax && formik.errors.unitMax}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="amount"
                                label="Amount"
                                onChange={formik.handleChange}
                                value={formik.values.amount}
                                fullWidth
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="incentive"
                                label="Incentive"
                                onChange={formik.handleChange}
                                value={formik.values.incentive}
                                fullWidth
                                error={formik.touched.incentive && Boolean(formik.errors.incentive)}
                                helperText={formik.touched.incentive && formik.errors.amount}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default BM6FieldExpenses;
