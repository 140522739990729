import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    CircularProgress,
    Select,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

//icons
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useEmployeeService from 'services/useEmployeeService';
//project imports
import { locations, roles } from 'store/constant';
import NoResults from 'assets/images/noresults.png';
import useSalariesService from 'services/useSalariesService';
import DBMSalarySheets from './generate-salaries/DBMSalarySheet';
import { LoadingButton } from '@mui/lab';
import useLocationService from 'services/useLocationService';

const SalaryCalculations = (props) => {
    //Constants
    const theme = useTheme();
    const navigate = useNavigate();
    const { getAllSalaries, deleteSalaries } = useSalariesService();
    const { getEmployees } = useEmployeeService();
    const { getLocationList } = useLocationService();
    //const { month, year } = props;
    const pageSize = 50;

    //States
    const [selectedRole, setSelectedRole] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState(1);
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const [employeeSalaryDetials, setEmployeeSalaryDetails] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [selectedEmployeeSalaryRecord, setSelectedEmployeeSalaryRecord] = useState();
    // const [employeeCount, setEmployeeCount] = useState();
    // const [currentPage, setCurrentPage] = useState(1);
    // const [currentSkipValue, setCurrentSkipValue] = useState(0);
    const [isError, setIsError] = useState();
    const [location, setLocation] = useState();

    //Methods

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const getEmployeeList = () => {
        // setEmployeeSalaryDetails(undefined);
        getAllSalaries(selectedRole, selectedLocation, selectedMonth, selectedYear)
            .then((response) => {
                console.log(response);
                //setEmployeeCount(response.data.count);
                // console.log('DRRRR', response.data, selectedRole, selectedMonth, selectedYear);
                setEmployeeSalaryDetails(response.data.salaries);
            })
            .catch((error) => setIsError(error.message));
    };

    const handleDelete = () => {
        deleteSalaries(selectedEmployeeSalaryRecord.id)
            .then((response) => {
                getEmployeeList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };

    const handleChange = (e) => {
        setSelectedLocation(e.target.value);
    };

    const handleGetLocation = () => {
        getLocationList()
            .then((response) => {
                console.log(response);
                setLocation(response.data.locations);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    //useEffects
    useEffect(() => {
        handleGetLocation();
    }, []);

    useEffect(() => {
        getEmployeeList();
    }, [selectedRole, selectedLocation, selectedMonth, selectedYear]);

    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<PaymentsIcon color="primary" />}
                    title="Salary Details"
                    sx={{ padding: 0 }}
                    action={
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate('/sales');
                            }}
                            sx={{ marginRight: 1 }}
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                    }
                />
                <Divider sx={{ marginY: 3 }} />
                <Typography variant="h4">Select parameters to view salaries</Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginY: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Select
                            labelId="role-select-label"
                            id="role-select"
                            name="role"
                            size="small"
                            sx={{ width: 300 }}
                            value={selectedRole}
                            onChange={(e) => {
                                setSelectedRole(e.target.value);
                            }}
                        >
                            {roles?.map((role, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        <Typography>{role}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <Select
                            labelId="location-select-label"
                            id="location-select"
                            name="location"
                            size="small"
                            sx={{ width: 200 }}
                            value={selectedLocation}
                            onChange={(e) => {
                                setSelectedLocation(e.target.value);
                            }}
                        >
                            {location?.map((location, index) => {
                                return (
                                    <MenuItem key={index} value={location.id}>
                                        <Typography>{location.city}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <TextField
                            label="Month"
                            //size="small"
                            type="number"
                            sx={{ width: 100 }}
                            value={selectedMonth}
                            onChange={(e) => {
                                setSelectedMonth(e.target.value);
                            }}
                        />
                        <TextField
                            label="Year"
                            //size="small"
                            type="number"
                            sx={{ width: 100 }}
                            value={selectedYear}
                            onChange={(e) => {
                                setSelectedYear(e.target.value);
                            }}
                        />
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                navigate('/read-only');
                            }}
                        >
                            View Salary Sheets
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                navigate('/generate-salaries');
                            }}
                        >
                            Edit Salaries
                        </Button>
                    </Stack>
                </Stack>
                {employeeSalaryDetials != undefined ? (
                    <>
                        {employeeSalaryDetials.length === 0 ? (
                            <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                <img src={NoResults} alt="No Result" width="150px" />
                                <Typography variant="h4" fontWeight={100}>
                                    There are no employee salary record found
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid item sm={12} md={12} lg={12} sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                    <TableContainer>
                                        <Table
                                            sx={{
                                                border: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                <TableCell sx={{ width: 70 }}>
                                                    <Typography variant="h5">Sr.No.</Typography>{' '}
                                                </TableCell>
                                                <TableCell sx={{ width: 150 }}>
                                                    <Typography variant="h5">Employee Code</Typography>{' '}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="h5">Employee Name</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 190 }}>
                                                    <Typography variant="h5">Employee Salary</Typography>
                                                </TableCell>
                                                {/* <TableCell sx={{ width: 190 }}>
                                                <Typography variant="h5">Recorded By</Typography>
                                            </TableCell> */}

                                                <TableCell sx={{ width: 120 }}></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {employeeSalaryDetials?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        {/* <TableCell align="left">
                                                        <Link href={'/sales-details?Id=' + row.id}>
                                                            {row.recordFYStart}
                                                            {row.recordFYEnd.toString().slice(-2)}
                                                            {row.recordNumber.toString().padStart(6, '0')}
                                                        </Link>
                                                    </TableCell> */}
                                                        <TableCell align="left">{row.userCode}</TableCell>
                                                        <TableCell align="left">{row.userName}</TableCell>
                                                        <TableCell align="left">{row.netSalary}</TableCell>
                                                        <TableCell align="right">
                                                            {/* <Tooltip title="Sales details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    navigate('/sales-details?Id=' + row.id);
                                                                }}
                                                            >
                                                                <ArticleIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit Sales Record details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedSalesRecord(row);
                                                                    setOpenForm(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                            <Tooltip title="Delete employee salary record">
                                                                <IconButton
                                                                    color="error"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelectedEmployeeSalaryRecord(row);
                                                                        setOpenDeleteDialog(true);
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {employeeSalaryDetials?.map((row, index) => (
                                    <Grid key={index} item xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                                        <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="h5">Employee Code:</Typography>
                                                <Typography>{row.userCode}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Employee Name:</Typography>
                                                <Typography>{row.userName}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Employee Salary:</Typography>
                                                <Typography>{row.netSalary}</Typography>
                                            </Stack>
                                            {/* <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                            <Typography variant="h5">Recorded By:</Typography>
                                            <Typography>{row.recordedBy}</Typography>
                                        </Stack>
                                        <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                            <Typography variant="h5">Note:</Typography>
                                            <Typography>{row.note}</Typography>
                                        </Stack> */}
                                            <Divider sx={{ mt: 1 }} />
                                            <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                {/* <Tooltip title="Sales details">
                                                <IconButton
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        navigate('/sales-details?Id=' + row.id);
                                                    }}
                                                >
                                                    <ArticleIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit Sales Record details">
                                                <IconButton
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedSalesRecord(row);
                                                        setOpenForm(true);
                                                    }}
                                                >
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                            </Tooltip> */}
                                                <Tooltip title="Delete employee salary record">
                                                    <IconButton
                                                        color="error"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedEmployeeSalaryRecord(row);
                                                            setOpenDeleteDialog(true);
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                        <Typography variant="h4" textAlign="center">
                            Getting Employee Salary Record List...
                        </Typography>
                        <CircularProgress />
                    </Stack>
                )}
            </MainCard>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete salary Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this salary details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> Salary deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default SalaryCalculations;
