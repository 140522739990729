import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

//MaterialUI Imports
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import PaymentIcon from '@mui/icons-material/Payment';

import { useFormik } from 'formik';
import * as Yup from 'yup';

//Third Party Imports
import useABMFieldExpensesService from 'services/useABMFieldExpensesService';
import useABMSalarySlabsService from 'services/useABMSalarySlabsService';

//Project Imports

const ABMFieldExpenses = (props) => {
    //Constants
    const { onSave, onCancel, selectedABMFieldExpenses } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const { getABMSalarySlabById } = useABMSalarySlabsService();
    const { addABMFieldExpenses, updateABMFieldExpenses } = useABMFieldExpensesService();

    //States
    const [loading, setLoading] = useState(false);
    const [abmSalarySlab, setABMSalarySlab] = useState([]);
    //Methods
    // const getABMSalarySlabList = () => {
    //     if (searchParams.get('Id') != null) {
    //         getABMSalarySlabById(searchParams.get('Id'))
    //             .then((response) => {
    //                 setABMSalarySlab(response.data);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // };
    // //useEffects
    // useEffect(() => {
    //     getABMSalarySlabList();
    // }, []);

    const formik = useFormik({
        initialValues: {
            id: selectedABMFieldExpenses.id,
            abmSalarySlabId: 1,
            unitMin: selectedABMFieldExpenses.unitMin,
            unitMax: selectedABMFieldExpenses.unitMax,
            fieldExpenses: selectedABMFieldExpenses.fieldExpenses,
            petrol: selectedABMFieldExpenses.petrol,
            incentive: selectedABMFieldExpenses.incentive
        },
        validationSchema: Yup.object({
            unitMin: Yup.number(),
            unitMax: Yup.number(),
            fieldExpenses: Yup.number(),
            petrol: Yup.number(),
            incentive: Yup.number()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedABMFieldExpenses.id != 0) {
                let data = {
                    abmSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    fieldExpenses: values.fieldExpenses,
                    petrol: values.petrol,
                    incentive: values.incentive
                };
                updateABMFieldExpenses(selectedABMFieldExpenses.id, data)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                let data = {
                    abmSalarySlabId: 1,
                    unitMin: values.unitMin,
                    unitMax: values.unitMax,
                    fieldExpenses: values.fieldExpenses,
                    petrol: values.petrol,
                    incentive: values.incentive
                };
                addABMFieldExpenses(data)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedABMFieldExpenses.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <PaymentIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New ABM Field Expenses</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <PaymentIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit ABM Field Expenses Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="unitMin"
                                label="Unit Min"
                                onChange={formik.handleChange}
                                value={formik.values.unitMin}
                                fullWidth
                                error={formik.touched.unitMin && Boolean(formik.errors.unitMin)}
                                helperText={formik.touched.unitMin && formik.errors.unitMin}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="unitMax"
                                label="Unit Max"
                                onChange={formik.handleChange}
                                value={formik.values.unitMax}
                                fullWidth
                                error={formik.touched.unitMax && Boolean(formik.errors.unitMax)}
                                helperText={formik.touched.unitMax && formik.errors.unitMin}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="fieldExpenses"
                                label="Field Expenses"
                                onChange={formik.handleChange}
                                value={formik.values.fieldExpenses}
                                fullWidth
                                error={formik.touched.fieldExpenses && Boolean(formik.errors.fieldExpenses)}
                                helperText={formik.touched.fieldExpenses && formik.errors.fieldExpenses}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="petrol"
                                label="Petrol(LTRS)"
                                onChange={formik.handleChange}
                                value={formik.values.petrol}
                                fullWidth
                                error={formik.touched.petrol && Boolean(formik.errors.petrol)}
                                helperText={formik.touched.petrol && formik.errors.petrol}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="incentive"
                                label="Incentive"
                                onChange={formik.handleChange}
                                value={formik.values.incentive}
                                fullWidth
                                error={formik.touched.incentive && Boolean(formik.errors.incentive)}
                                helperText={formik.touched.incentive && formik.errors.incentive}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default ABMFieldExpenses;
