import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FunctionsIcon from '@mui/icons-material/Functions';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

//services imports
import useEmployeeService from 'services/useEmployeeService';
import useTMSalarySlabsService from 'services/useTMSalarySlabsService';
import useTMFieldExpensesService from 'services/useTMFieldExpensesService';
import useTMDailyAllowanceService from 'services/useTMDailyAllowanceService';
import useTMPRIsService from 'services/useTMPRIsService';

//project imports
//import GCCard from './GCCard';
//import TMCard from './TMCard';
import ROTMSalarySheet from './ROTMSalarySheet';
import useSalariesService from 'services/useSalariesService';

const ROTM = (props) => {
    const { selectedRole, selectedLocation, selectedMonth, selectedYear } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    //states
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState();
    const [tmSalarySlabDetails, setTMSalarySlabDetails] = useState();
    const [tmFiledExpensesDetails, setTMFiledExpensesDetails] = useState();
    const [tmDailyAllowanceDetails, setTMDailyAllowanceDetails] = useState();
    const [tmPRIsDetails, setTMPRIsDetails] = useState();
    const [salaryReadOnly, setSalaryReadOnly] = useState();
    //services
    const { getEmployees } = useEmployeeService();
    const { getTMSalarySlab } = useTMSalarySlabsService();
    const { getTMDailyAllowance } = useTMDailyAllowanceService();
    const { getTMFieldExpenses } = useTMFieldExpensesService();
    const { getTMPRIs } = useTMPRIsService();
    const { getAllSalaries } = useSalariesService();

    //handlers
    const handleGetEmployees = () => {
        getEmployees('', selectedRole, selectedLocation, 0, 100000)
            .then((response) => {
                if (response.status == 200) {
                    setEmployees(response.data.users);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalaryReadOnly = () => {
        getAllSalaries(selectedRole, selectedLocation, selectedMonth, selectedYear)
            .then((response) => {
                console.log('response', selectedRole, selectedLocation, selectedMonth, selectedYear);
                if (response.status == 200) {
                    setSalaryReadOnly(response.data.salaries);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalarySlabs = () => {
        getTMSalarySlab()
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.list.length > 0) {
                        setTMSalarySlabDetails(response.data.list[0]);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetDailyAllowance = () => {
        getTMDailyAllowance(tmSalarySlabDetails.id)
            .then((response) => {
                if (response.status == 200) {
                    setTMDailyAllowanceDetails(response.data.tmdAs);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleGetFieldExpenses = () => {
        getTMFieldExpenses(tmSalarySlabDetails.id)
            .then((response) => {
                if (response.status == 200) {
                    setTMFiledExpensesDetails(response.data.list);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleGetPRIs = () => {
        getTMPRIs(tmSalarySlabDetails.id)
            .then((response) => {
                if (response.status == 200) {
                    setTMPRIsDetails(response.data.list);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleGetEmployees();
        handleGetSalarySlabs();
    }, [selectedRole, selectedLocation]);
    useEffect(() => {
        if (tmSalarySlabDetails) {
            handleGetDailyAllowance();
        }
    }, [tmSalarySlabDetails]);
    useEffect(() => {
        if (tmSalarySlabDetails) {
            handleGetFieldExpenses();
        }
    }, [tmSalarySlabDetails]);

    useEffect(() => {
        if (tmSalarySlabDetails) {
            handleGetPRIs();
        }
    }, [tmSalarySlabDetails]);
    useEffect(() => {
        handleGetSalaryReadOnly();
    }, [selectedRole, selectedLocation, selectedMonth, selectedYear]);
    return (
        <Box>
            <Grid container spacing={2}>
                {employees ? (
                    <>
                        {/* {employees.map((employee, index) => { */}

                        <Grid item xs={12} md={12}>
                            <ROTMSalarySheet
                                employee={employees}
                                tmSalarySlabDetails={tmSalarySlabDetails}
                                tmDailyAllowanceDetails={tmDailyAllowanceDetails}
                                tmFiledExpensesDetails={tmFiledExpensesDetails}
                                tmPRIsDetails={tmPRIsDetails}
                                salaryData={salaryReadOnly}
                                onRefresh={() => {
                                    handleGetSalarySlabs();
                                    handleGetDailyAllowance();
                                    handleGetFieldExpenses();
                                    handleGetPRIs();
                                }}
                                location={selectedLocation}
                                month={selectedMonth}
                                year={selectedYear}
                            />
                        </Grid>

                        {/* })} */}
                    </>
                ) : null}
            </Grid>
        </Box>
    );
};

export default ROTM;
