import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useGLSalarySlabItemsService = () => {
    const getGLSalarySlabItems = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/GLFieldExpences/by-gl-salaryslab-id?glSalarySlabId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addGLSalarySlabItems = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/GLFieldExpences`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateGLSalarySlabItems = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/GLFieldExpences?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteGLSalarySlabItems = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/GLFieldExpences/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getGLSalarySlabItems, addGLSalarySlabItems, updateGLSalarySlabItems, softDeleteGLSalarySlabItems };
};

export default useGLSalarySlabItemsService;
