//import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const usePackService = () => {
    const getPacks = async () => {
        try {
            const response = await axiosAuth.get(`/api/Packs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getPackById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Packs/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addPacks = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Packs`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updatePacks = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/Packs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeletePacks = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Packs/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getPacks, addPacks, updatePacks, softDeletePacks, getPackById };
};

export default usePackService;
