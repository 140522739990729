import React, { useState } from 'react';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import { Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import InventoryIcon from '@mui/icons-material/Inventory';

//Third Party Imports
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import usePackService from 'services/usePackService';

const PackForm = (props) => {
    //Constants
    const navigate = useNavigate();
    const { addPacks, updatePacks } = usePackService();
    const { onSave, onCancel, selectedPack } = props;

    //States
    const [loading, setLoading] = useState(false);

    //Methods

    // const calculateUnitPrice = (values) => {
    //     const price = parseFloat(values.price);
    //     const unit = price / 2000;
    //     return unit.toFixed(2);
    // };

    const formik = useFormik({
        initialValues: {
            id: selectedPack.id,
            name: selectedPack.name,
            description: selectedPack.description,
            unit: selectedPack.unit,
            price: selectedPack.price
        },
        validationSchema: Yup.object({
            name: Yup.string().max(50, 'Name must be 50 characters or less').required('Name is required'),
            description: Yup.string(),
            unit: Yup.number()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedPack.id != 0) {
                updatePacks(selectedPack.id, values)
                    .then((response) => {
                        if (response.status === 200) {
                            //setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                addPacks(values)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);

                            if (response.data.newId != undefined) {
                                navigate('/pack-details?Id=' + response.data.newId);
                            }
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
            //setSubmitting(false);
        }
    });
    return (
        <>
            <Grid align="center">
                {selectedPack.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <InventoryIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New Pack</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <InventoryIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit Pack Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                name="name"
                                label="Name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                fullWidth
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                name="description"
                                label="Description"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                fullWidth
                                multiline
                                rows={3}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                name="unit"
                                label="Unit"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.unit}
                                fullWidth
                                error={formik.touched.unit && Boolean(formik.errors.unit)}
                                helperText={formik.touched.unit && formik.errors.unit}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                name="price"
                                label="Price"
                                type="number"
                                //onChange={formik.handleChange}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    //calculateUnitPrice(e);
                                    //console.log(e.target.value);
                                    formik.setFieldValue('unit', (e.target.value / 2000).toFixed(2));
                                }}
                                value={formik.values.price}
                                fullWidth
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                helperText={formik.touched.price && formik.errors.price}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid>
                        <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                            Save
                        </LoadingButton>
                        <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    );
};

export default PackForm;
