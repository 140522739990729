import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useBM5SalarySlabs = () => {
    const getBM5SalarySlab = async () => {
        try {
            const response = await axiosAuth.get(`/api/BM5SalarySlabs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getBM5SalarySlabById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/BM5SalarySlabs/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateBM5SalarySlab = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/BM5SalarySlabs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getBM5SalarySlab, getBM5SalarySlabById, updateBM5SalarySlab };
};

export default useBM5SalarySlabs;
