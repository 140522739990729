import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';
const useMonthlySaleUnitsService = () => {
    const getMonthlySaleUnits = async (soldby, month, year) => {
        try {
            const response = await axiosAuth.get(`/api/MonthlySaleUnits/total-sale-unit?soldby=${soldby}&month=${month}&year=${year}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getMonthlySaleUnits };
};

export default useMonthlySaleUnitsService;
