import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Button,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Stack,
    Alert,
    Snackbar,
    Pagination,
    TextField,
    InputAdornment,
    Grid,
    Select,
    MenuItem,
    Card,
    Link,
    FormControl,
    InputLabel
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import SearchIcon from '@mui/icons-material/Search';
import ShopIcon from '@mui/icons-material/Shop';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import usePurchaseRecordService from 'services/usePurchaseRecordService';
import PurchaseRecordForm from './PurchaseRecordForm';
import NoResults from 'assets/images/noresults.png';
import { locations } from 'store/constant';
import useLocationService from 'services/useLocationService';

const PurchaseRecord = () => {
    //Constants
    const theme = useTheme();
    const navigate = useNavigate();
    const { deletePurchaseRecord, getPurchaseRecord } = usePurchaseRecordService();
    const { getLocationList } = useLocationService();
    const skip = 0;
    const pageSize = 10;

    //States
    const [purchaseDetails, setPurchaseDetails] = useState([]);
    const [selectedPurchaseRecord, setSelectedPurchaseRecord] = useState();

    const [purchaseCount, setPurchaseCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSkipValue, setCurrentSkipValue] = useState(0);

    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isError, setIsError] = useState();
    const [openToast, setOpenToast] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(-1);
    const [location, setLocation] = useState();
    //Methods
    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getPurchaseRecordList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const getPurchaseRecordList = () => {
        getPurchaseRecord(currentSkipValue, pageSize, selectedLocation)
            .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    setPurchaseCount(response.data.count);
                    setPurchaseDetails(response.data.list);
                }
            })
            .catch((error) => {
                setIsError(error.message);
            });
    };

    const handleDelete = () => {
        deletePurchaseRecord(selectedPurchaseRecord.id)
            .then((response) => {
                console.log('Item deleted successfully', response);
                getPurchaseRecordList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };

    const handleChange = (e) => {
        setSelectedLocation(e.target.value);
    };
    const handleGetLocation = () => {
        getLocationList()
            .then((response) => {
                console.log(response);
                setLocation(response.data.locations);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        handleGetLocation();
    }, []);
    //UseEffects

    useEffect(() => {
        setPurchaseDetails(undefined);
        getPurchaseRecordList();
    }, [currentPage, selectedLocation]);

    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<ShopIcon color="primary" />}
                    title="Purchase List"
                    sx={{ padding: 0 }}
                    action={
                        <Grid container>
                            <Grid item sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    align="right"
                                    onClick={() => {
                                        setSelectedPurchaseRecord({
                                            id: 0,
                                            date: new Date().toISOString().split('T')[0],
                                            recordedBy: 0,
                                            locationId: 0,
                                            note: ''
                                        });
                                        setOpenForm(true);
                                    }}
                                >
                                    Add New Purchase
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />

                <Divider sx={{ marginY: 3 }} />

                <Grid container spacing={2}>
                    <Grid item xs={12} align="right" sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedPurchaseRecord({
                                    id: 0,
                                    date: new Date().toISOString().split('T')[0],
                                    recordedBy: 0,
                                    locationId: 0,
                                    note: ''
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add New Purchase
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        {' '}
                        {purchaseDetails != undefined && (
                            <>
                                {purchaseCount != undefined && purchaseCount != 0 ? (
                                    <Pagination
                                        count={Math.ceil(purchaseCount / pageSize)}
                                        variant="outlined"
                                        shape="rounded"
                                        showFirstButton
                                        showLastButton
                                        color="primary"
                                        sx={{ marginY: 2 }}
                                        onChange={(event, page) => {
                                            setCurrentSkipValue((page - 1) * pageSize);
                                            setCurrentPage(page);
                                        }}
                                    />
                                ) : null}
                            </>
                        )}
                    </Grid>
                    <Grid item sm={2} xs={12} sx={{ width: '20%' }}>
                        {' '}
                        <FormControl fullWidth>
                            <InputLabel>Location</InputLabel>
                            <Select
                                labelId="location-select-label"
                                id="location-select"
                                label="Location"
                                size="small"
                                fullWidth
                                name="location"
                                value={selectedLocation}
                                onChange={handleChange}
                            >
                                <MenuItem value={-1}>All</MenuItem>
                                {location?.map((location, index) => {
                                    return (
                                        <MenuItem key={index} value={location.id}>
                                            <Stack direction="column">
                                                <Typography>{location.city}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {purchaseDetails != undefined ? (
                    <>
                        {purchaseDetails?.length === 0 ? (
                            <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                <img src={NoResults} alt="No Result" width="150px" />
                                <Typography variant="h4" fontWeight={100}>
                                    There are no purchase record found
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid item sm={12} md={12} lg={12} sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}>
                                    <TableContainer>
                                        <Table
                                            sx={{
                                                border: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                <TableCell sx={{ width: 70 }}>
                                                    <Typography variant="h5">Sr.No.</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 150 }}>
                                                    <Typography variant="h5">Record Number</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 120 }}>
                                                    <Typography variant="h5">Date</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 200 }}>
                                                    <Typography variant="h5">Recorded By</Typography>
                                                </TableCell>
                                                <TableCell sx={{ width: 200 }}>
                                                    <Typography variant="h5">Location</Typography>
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {purchaseDetails?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Link href={'/purchase-details?Id=' + row.id}>
                                                                P{row.recordFYStart}
                                                                {row.recordFYEnd.toString().slice(-2)}
                                                                {row.recordNumber.toString().padStart(6, '0')}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="left">{new Date(row.date).toLocaleDateString('hi-IN')}</TableCell>
                                                        <TableCell align="left">{row.recordedByName}</TableCell>
                                                        <TableCell align="left">{row.locationName}</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Purchase details">
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        navigate('/purchase-details?Id=' + row.id);
                                                                    }}
                                                                >
                                                                    <ArticleIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit Purchase Record details">
                                                                <IconButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelectedPurchaseRecord(row);
                                                                        setOpenForm(true);
                                                                    }}
                                                                >
                                                                    <EditIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Purchase Record">
                                                                <IconButton
                                                                    color="error"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setSelectedPurchaseRecord(row);
                                                                        setOpenDeleteDialog(true);
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {purchaseDetails.map((row, index) => (
                                    <Grid key={index} item xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}>
                                        <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                            <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                <Typography variant="h5">Record Number:</Typography>
                                                <Typography>
                                                    P{row.recordFYStart}
                                                    {row.recordFYEnd.toString().slice(-2)}
                                                    {row.recordNumber.toString().padStart(6, '0')}
                                                </Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Date:</Typography>
                                                <Typography>{new Date(row.date).toLocaleDateString('hi-IN')}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Recorded By:</Typography>
                                                <Typography>{row.recordedByName}</Typography>
                                            </Stack>
                                            <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                <Typography variant="h5">Location:</Typography>
                                                <Typography>{row.locationName}</Typography>
                                            </Stack>
                                            <Divider sx={{ mt: 1 }} />
                                            <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                <Tooltip title="Purchase details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            navigate('/purchase-details?Id=' + row.id);
                                                        }}
                                                    >
                                                        <ArticleIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit Purchase Record details">
                                                    <IconButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedPurchaseRecord(row);
                                                            setOpenForm(true);
                                                        }}
                                                    >
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Purchase Record">
                                                    <IconButton
                                                        color="error"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedPurchaseRecord(row);
                                                            setOpenDeleteDialog(true);
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                        <Typography variant="h4" textAlign="center">
                            Getting Purchase Record List...
                        </Typography>
                        <CircularProgress />
                    </Stack>
                )}
            </MainCard>
            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <PurchaseRecordForm onSave={handleSave} onCancel={handleCancel} selectedPurchaseRecord={selectedPurchaseRecord} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete Purchase Record Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this purchase details ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                <Alert onClose={handleCloseToast} severity="success" sx={{ width: '100%', backgroundColor: theme.palette.success.main }}>
                    <Typography sx={{ color: theme.palette.grey[50] }}>Purchase record deleted succesfully!!</Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export default PurchaseRecord;
