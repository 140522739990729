import React, { useState, useEffect } from 'react';

//MaterialUI Imports
import { Divider, Stack, Typography, Card, TextField, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//icon imports
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
//import SalaryDetailComponent from '../SalaryDetailComponent';
import useSalariesService from 'services/useSalariesService';
import SalaryReadOnly from '../SalaryReadOnly';
//import useAmountTransactionService from 'services/useAmountTransactionService';
import { daysInMonth } from 'utils/DateOperations';

const ROSESalarySheet = (props) => {
    const { employee, salarySlabDetails, filedExpensesDetails, onRefresh, month, year, salaryData } = props;
    const theme = useTheme();
    //const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState();
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState();
    const [editMode, setEditMode] = useState(false);

    const [pendingAmountDetails, setPendingAmountDetails] = useState();
    const [salaryDetails, SetSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        workDays: 0,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        //adjustment: 0,
        petrol: 0,
        incentive: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0
        //remarks: ''
    });

    const [appliedSalarySlab, setAppliedSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        pri: 0,
        da: 0,
        ta: 0,
        incentive: 0,
        subTotal: 0,
        deduction: 0
    });

    //Methods

    return (
        <>
            <Grid container spacing={2}>
                {salaryData?.map((row, index) => (
                    <Grid key={index} item sm={6} md={6}>
                        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        {row.userCode}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.secondary.main}>
                                        {row.userName}
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        |
                                    </Typography>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        +91-{row.mobileNumber}
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Divider sx={{ marginY: 2 }} />

                            <Stack direction="row" spacing={3}>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Units Sold
                                    </Typography>
                                    <Typography>{row.unitSold}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Work Days
                                    </Typography>
                                    <Typography>{row.workDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        DA Days
                                    </Typography>
                                    <Typography>{row.daDays}</Typography>
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h5" color={theme.palette.grey[500]}>
                                        Petrol Rate
                                    </Typography>
                                    <Typography>₹ {row.petrolRate}</Typography>
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginY: 2 }} />
                            <Stack spacing={1} sx={{ marginX: 2 }}>
                                <SalaryReadOnly
                                    title="Fixed Salary"
                                    // description={`₹ ${row.fixedSalary} (From Table)`}
                                    value={row.fixedSalary}
                                />
                                <SalaryReadOnly
                                    title="Filed Expenses"
                                    // description={`${row.unitSold} Units x ₹ ${row.fieldExpences} (From Table)`}
                                    value={row.fieldExpences}
                                />
                                <SalaryReadOnly
                                    title="PRI"
                                    // description={`₹ ${row.pri} (From Table)`}
                                    value={row.pri}
                                />
                                <SalaryReadOnly
                                    title="D.A."
                                    // description={`${row.workDays} Days x ₹ ${row.da} (From Table)`}
                                    value={row.da}
                                />
                                <SalaryReadOnly
                                    title="T.A."
                                    // description={`${row.workDays} Days x ₹ ${row.ta} (From Table)`}
                                    value={row.ta}
                                />
                                <SalaryReadOnly title="Petrol" value={row.petrol} />
                                <SalaryReadOnly
                                    title="Incentive."
                                    // description={` ₹ ${row.groupIncentive} (From Table)`}
                                    value={row.groupIncentive}
                                />
                                <Divider />
                                <SalaryReadOnly title="Sub Total" value={row.subTotal} />
                                <Divider />
                                <SalaryReadOnly
                                    title="Deduction"
                                    //description={` ₹ ${salaryDetails.deduction}`}
                                    value={row.deduction}
                                />
                                <Divider />
                                <SalaryReadOnly title="Net Salary" value={row.netSalary} />
                                <Divider />
                                <SalaryReadOnly title="Remark" value={row.remark} />
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default ROSESalarySheet;
