import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useABMDailyAllowanceService = () => {
    const getABMDailyAllowanceById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/ABMDAs/by-abm-salaryslab-id?salarySlabId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addABMDailyAllowance = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/ABMDAs`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateABMDailyAllowance = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/ABMDAs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteABMDailyAllowance = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/ABMDAs/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getABMDailyAllowanceById, addABMDailyAllowance, updateABMDailyAllowance, softDeleteABMDailyAllowance };
};

export default useABMDailyAllowanceService;
