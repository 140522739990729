const convertDateToLocal = (dateInput) => {
    let dateValue = new Date(dateInput); //.toLocaleString('hi-IN');
    let day = dateValue.getDate().toString().padStart(2, '0');
    let month = (dateValue.getMonth() + 1).toString().padStart(2, '0');
    let year = dateValue.getFullYear();
    return `${day}-${month}-${year}`;
};

const convertDateToLocalReverse = (dateInput) => {
    let dateValue = new Date(dateInput); //.toLocaleString('hi-IN');
    let day = dateValue.getDate().toString().padStart(2, '0');
    let month = (dateValue.getMonth() + 1).toString().padStart(2, '0');
    let year = dateValue.getFullYear();
    return `${year}-${month}-${day}`;
};

const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
};

export { convertDateToLocal, convertDateToLocalReverse, daysInMonth };
