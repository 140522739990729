import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useSEFieldExpencessService = () => {
    const getSEFieldExpences = async (salarySlabId) => {
        try {
            const response = await axiosAuth.get(`/api/SEFieldExpences/by-se-salaryslab-id?salarySlabId=${salarySlabId}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addSEFieldExpences = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/SEFieldExpences`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateSEFieldExpences = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/SEFieldExpences?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteSEFieldExpences = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/SEFieldExpences/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const deleteSEFieldExpences = async (id) => {
        try {
            const response = await axiosAuth.delete(`/SEFieldExpences?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getSEFieldExpences, addSEFieldExpences, updateSEFieldExpences, softDeleteSEFieldExpences, deleteSEFieldExpences };
};

export default useSEFieldExpencessService;
