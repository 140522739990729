import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useBM6SalarySlabs = () => {
    const getBM6SalarySlab = async () => {
        try {
            const response = await axiosAuth.get(`/api/BM6SalarySlabs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getBM6SalarySlabById = async (id) => {
        try {
            const response = await axiosAuth.get(`${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateBM6SalarySlab = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/BM6SalarySlabs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getBM6SalarySlab, getBM6SalarySlabById, updateBM6SalarySlab };
};

export default useBM6SalarySlabs;
