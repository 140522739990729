import React, { useEffect, useState } from 'react';

//Material Imports
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import NoResults from 'assets/images/noresults.png';
import useABMSalarySlabsService from 'services/useABMSalarySlabsService';
import useABMFieldExpensesService from 'services/useABMFieldExpensesService';
import UseABMPRisService from 'services/useABMPRIsService';
import ABMFieldExpenses from './ABMFieldExpenses';
import ABMPRI from './ABMPRI';
import useABMDailyAllowanceService from 'services/useABMDailyAllowanceService';
import ABMDailyAllowance from './ABMDailyAllowance';

const ABMSalarySlab = () => {
    //Constants
    const theme = useTheme();
    const { getABMSalarySlab, updateABMSalarySlab } = useABMSalarySlabsService();
    const { getABMFieldExpenses, softDeleteABMFieldExpenses } = useABMFieldExpensesService();
    const { getABMPRIs, softDeleteABMPRIs } = UseABMPRisService();
    const { getABMDailyAllowanceById, softDeleteABMDailyAllowance } = useABMDailyAllowanceService();

    //States
    const [loading, setLoading] = useState(false);
    const [loadingABMSalaryUpdate, setLoadingABMSalaryUpdate] = useState(false);
    const [abmSalarySlabDetails, setABMSalarySlabDetails] = useState([]);
    const [abmFieldExpensesDetails, setABMFieldExpensesDetails] = useState([]);
    const [abmDAsDetails, setABMDAsDetails] = useState([]);
    const [abmPRIsDetails, setABMPRIsDetails] = useState([]);

    const [selectedABMFieldExpenses, setSelectedABMFieldExpenses] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);

    const [selectedABMPRIs, setSelectedABMPRIs] = useState('');
    const [openFormABMPRIs, setOpenFormABMPRIs] = useState(false);
    const [openABMPRIsDeleteDialog, setOpenABMPRIsDeleteDialog] = useState(false);
    const [openABMPRIsToast, setABMPRIsOpenToast] = useState(false);

    const [selectedABMDAs, setSelectedABMDAs] = useState('');
    const [openFormABMDAs, setOpenFormABMDAs] = useState(false);
    const [openABMDAsDeleteDialog, setOpenABMDAsDeleteDialog] = useState(false);
    const [openABMDAsToast, setABMDAsOpenToast] = useState(false);
    const [isError, setIsError] = useState();

    const [abmFixedSalary, setABMFixedSalary] = useState('');

    const [abmSalarySlabId, setABMSalarySlabId] = useState(0);

    // Methods

    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getABMFieldExpensesList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };
    //ABM PRIs
    const handleClose = () => {
        setOpenFormABMPRIs(false);
    };

    const handleSaveABMPRIs = () => {
        getABMPRIsList();
    };

    const handleCloseABMPRIsToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setABMPRIsOpenToast(false);
    };

    //ABM Daily Allowance
    const handleCloseDAs = () => {
        setOpenFormABMDAs(false);
    };

    const handleSaveABMDAs = () => {
        getABMDAsList();
    };

    const handleCloseABMDAsToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setABMPRIsOpenToast(false);
    };

    //ABM Salary Slab
    const getABMSalarySlabList = () => {
        setABMSalarySlabDetails(undefined);
        getABMSalarySlab()
            .then((response) => {
                console.log(response.data);
                setABMSalarySlabDetails(response.data.list);
                setABMFixedSalary(response.data.list[0].fixedSalary);
                setABMSalarySlabId(response.data.list[0].id);
            })
            .catch((error) => setIsError(error.message));
    };

    //ABM Daily Allowance
    const getABMDAsList = () => {
        setABMDAsDetails(undefined);
        getABMDailyAllowanceById(abmSalarySlabId)
            .then((response) => {
                console.log(response);
                setABMDAsDetails(response.data.abmdAs);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleABMDAsDelete = () => {
        setLoading(true);
        softDeleteABMDailyAllowance(selectedABMDAs.id)
            .then((response) => {
                getABMDAsList();
                setABMDAsOpenToast(true);
                setOpenABMDAsDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenABMDAsDeleteDialog(false);
            });
    };

    //ABM Field Expenses
    const getABMFieldExpensesList = () => {
        setABMFieldExpensesDetails(undefined);
        getABMFieldExpenses(abmSalarySlabId)
            .then((response) => {
                console.log(response);
                setABMFieldExpensesDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleDelete = () => {
        setLoading(true);
        softDeleteABMFieldExpenses(selectedABMFieldExpenses.id)
            .then((response) => {
                getABMFieldExpensesList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };

    //ABM PRIs
    const getABMPRIsList = () => {
        setABMPRIsDetails(undefined);
        getABMPRIs(abmSalarySlabId)
            .then((response) => {
                console.log(response);
                setABMPRIsDetails(response.data.list);
            })
            .catch((error) => setIsError(error.message));
    };
    const handleABMPRIsDelete = () => {
        setLoading(true);
        softDeleteABMPRIs(selectedABMPRIs.id)
            .then((response) => {
                getABMPRIsList();
                setABMPRIsOpenToast(true);
                setOpenABMPRIsDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenABMPRIsDeleteDialog(false);
            });
    };

    //Update ABM Salary
    const handleUpdate = () => {
        setLoadingABMSalaryUpdate(true);
        let data = {
            fixedSalary: abmFixedSalary
        };
        updateABMSalarySlab(abmSalarySlabDetails[0].id, data).then((response) => {
            setLoadingABMSalaryUpdate(false);
            console.log('UPDATE CALL', response);
        });
    };

    //UseEffects
    useEffect(() => {
        getABMSalarySlabList();
        // getABMFieldExpensesList();
        // getABMPRIsList();
        // getABMDAsList();
    }, []);
    useEffect(() => {
        getABMFieldExpensesList();
    }, [abmSalarySlabId]);
    useEffect(() => {
        getABMPRIsList();
    }, [abmSalarySlabId]);
    useEffect(() => {
        getABMDAsList();
    }, [abmSalarySlabId]);

    return (
        <>
            <MainCard>
                <CardHeader title="ABM Salary Slabs" avatar={<PaymentsIcon color="primary" />} sx={{ padding: 0 }} />
                <Divider sx={{ marginY: 3 }} />
                <Grid container spacing={2} direction="row" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Grid item sm={2} xs={12}>
                        <Typography variant="h5">Fixed Salary :</Typography>
                    </Grid>
                    {abmFixedSalary != undefined || abmFixedSalary != null ? (
                        <Grid item sm={4} xs={12}>
                            <TextField
                                id="Fixed Salary"
                                type="number"
                                name="abmFixedSalary"
                                label="Fixed Salary"
                                variant="outlined"
                                value={abmFixedSalary}
                                fullWidth
                                onChange={(event) => {
                                    setABMFixedSalary(event.target.value);
                                    //console.log(abmFixeSalary);
                                }}
                            ></TextField>
                        </Grid>
                    ) : null}

                    <Grid item sm={4} xs={12}>
                        <LoadingButton
                            loading={loadingABMSalaryUpdate}
                            onClick={() => {
                                handleUpdate();
                            }}
                            variant="contained"
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 2 }} />
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">ABM DAs</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            size="small"
                            onClick={() => {
                                setSelectedABMDAs({
                                    id: 0,
                                    abmSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    amount: 0,
                                    campToCamp: 0
                                });
                                setOpenFormABMDAs(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {abmDAsDetails != undefined || abmDAsDetails != null ? (
                            <>
                                {abmDAsDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no ABM DAs details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer sx={{ maxWidth: 500 }}>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            <Typography variant="h5">Sr. No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5"> Productivity</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell>
                                                            <Typography variant="h5">Amount P/H</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5" sx={{ width: 100 }}>
                                                                Camp To Camp{' '}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 120 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {abmDAsDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>

                                                                <TableCell align="left">{row.amount}</TableCell>
                                                                <TableCell align="left">{row.campToCamp}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit ABM DAs details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedABMDAs(row);
                                                                                setOpenFormABMDAs(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete ABM DAs">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedABMDAs(row);
                                                                                setOpenABMDAsDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {abmDAsDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>

                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Amount:</Typography>
                                                        <Typography>{row.amount}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Camp To Camp:</Typography>
                                                        <Typography>{row.campToCamp}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit ABM DAs details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedABMDAs(row);
                                                                    setOpenFormABMDAs(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete ABM DAs">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedABMDAs(row);
                                                                    setOpenABMDAsDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 2 }} />
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">ABM Field Expenses</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            size="small"
                            onClick={() => {
                                setSelectedABMFieldExpenses({
                                    id: 0,
                                    abmSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    fieldExpenses: 0,
                                    petrol: 0,
                                    incentive: 0
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {abmFieldExpensesDetails != undefined || abmFieldExpensesDetails != null ? (
                            <>
                                {abmFieldExpensesDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h5" fontWeight={100}>
                                            There are no ABM field expenses details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer sx={{ maxWidth: 500 }}>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            <Typography variant="h5">Sr. No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">Units</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell sx={{ width: 90 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 90 }}>
                                                            <Typography variant="h5">Petrol(LTRS)</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 90 }}>
                                                            <Typography variant="h5">Incentive</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 110 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {abmFieldExpensesDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>

                                                                <TableCell align="left">{row.fieldExpenses}</TableCell>
                                                                <TableCell align="left">{row.petrol}</TableCell>
                                                                <TableCell align="left">{row.incentive}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit ABM field expenses details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedABMFieldExpenses(row);
                                                                                setOpenForm(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete ABM field expenses">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedABMFieldExpenses(row);
                                                                                setOpenDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {abmFieldExpensesDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>

                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Field Expenses:</Typography>
                                                        <Typography>{row.fieldExpenses}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Petrol(LTRS):</Typography>
                                                        <Typography>{row.petrol}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Incentive:</Typography>
                                                        <Typography>{row.incentive}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit ABM field expenses details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedABMFieldExpenses(row);
                                                                    setOpenForm(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete ABM field expenses">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedABMFieldExpenses(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* }
                /> */}
                <Divider sx={{ marginY: 2 }} />
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">ABM PRIs</Typography>
                    </Grid>
                    <Grid item align="right" sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            size="small"
                            onClick={() => {
                                setSelectedABMPRIs({
                                    id: 0,
                                    abmSalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    pri: 0
                                });
                                setOpenFormABMPRIs(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        {abmPRIsDetails != undefined || abmPRIsDetails != null ? (
                            <>
                                {abmPRIsDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no ABM pri details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer sx={{ maxWidth: 500 }}>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            <Typography variant="h5">Sr. No.</Typography>{' '}
                                                        </TableCell>
                                                        <TableCell sx={{ width: 150 }}>
                                                            <Typography variant="h5">P/H Productivity</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell>
                                                            <Typography variant="h5">Amount P/H</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 110 }}></TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {abmPRIsDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.unitMin} - {row.unitMax}
                                                                </TableCell>

                                                                <TableCell align="left">{row.pri}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit ABM PRIs details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedABMPRIs(row);
                                                                                setOpenFormABMPRIs(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete ABM PRIs">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedABMPRIs(row);
                                                                                setOpenABMPRIsDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {abmPRIsDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>

                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">PRIs:</Typography>
                                                        <Typography>{row.pri}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit ABM PRIs details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedABMPRIs(row);
                                                                    setOpenFormABMPRIs(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete ABM PRIs">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedABMPRIs(row);
                                                                    setOpenABMPRIsDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* }
                /> */}
            </MainCard>
            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <ABMFieldExpenses onSave={handleSave} onCancel={handleCancel} selectedABMFieldExpenses={selectedABMFieldExpenses} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete ABM Field Expenses Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this ABM field expenses details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> ABM field expenses deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>

            {/* ---------------ABM PRIs----------------------------- */}
            <Dialog open={openFormABMPRIs} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogContent>
                    <ABMPRI onSave={handleSaveABMPRIs} onCancel={handleClose} selectedABMPRIs={selectedABMPRIs} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openABMPRIsDeleteDialog}
                onClose={() => {
                    setOpenABMPRIsDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete ABM PRIs Expenses Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this ABM PRIs details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleABMPRIsDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenABMPRIsDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openABMPRIsToast} autoHideDuration={6000} onClose={handleCloseABMPRIsToast}>
                    <Alert
                        onClose={handleCloseABMPRIsToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> ABM PRIs deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>

            {/* ----------------------------ABM DAs------------------------------------ */}

            <Dialog open={openFormABMDAs} onClose={handleCloseDAs} fullWidth maxWidth="sm">
                <DialogContent>
                    <ABMDailyAllowance onSave={handleSaveABMDAs} onCancel={handleCloseDAs} selectedABMDAs={selectedABMDAs} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openABMDAsDeleteDialog}
                onClose={() => {
                    setOpenABMDAsDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete ABM Daily Allowance Expenses Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this ABM DAs details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleABMDAsDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenABMDAsDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openABMDAsToast} autoHideDuration={6000} onClose={handleCloseABMDAsToast}>
                    <Alert
                        onClose={handleCloseABMDAsToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> ABM DAs deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default ABMSalarySlab;
