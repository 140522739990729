import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useCategoryService = () => {
    const getCategory = async () => {
        try {
            const response = await axiosAuth.get(`/api/Categories`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const addCategory = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Categories`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateCategory = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/Categories?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteCategory = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Categories/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return { getCategory, addCategory, updateCategory, softDeleteCategory };
};

export default useCategoryService;
