import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FunctionsIcon from '@mui/icons-material/Functions';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

//services imports
import useEmployeeService from 'services/useEmployeeService';
import useSESalarySlabsService from 'services/useSESalarySlabsService';
import useSEFieldExpencessService from 'services/useSEFieldExpencessService';

//project imports
import { locations, roles } from 'store/constant';
import { LoadingButton } from '@mui/lab';
// import SECard from './SESalarySheet/SECard';
// import SESalarySheets from './SESalarySheet';
// import GCSalarySheets from './GCSalarySheet';
// import GLSalarySheets from './GLSalarySheet';
// import TMSalarySheets from './TMSalarySheet';
// import ABMSalarySheets from './ABMSalarySheet';
// import DBMSalarySheets from './DBMSalarySheet';
// import BM5SalarySheets from './BM5SalarySheet';
// import BM6SalarySheets from './BM6SalarySheet';
import RODBMSalarySheets from './DBMReadOnly/RODBMSalarySheets';
import RODBM from './DBMReadOnly';
import ABMSalarySheets from '../generate-salaries/ABMSalarySheet';
import ROABM from './ABMReadOnly';
import ROGC from './GCReadOnly';
import ROGL from './GLReadOnly';
import ROSE from './SEReadOnly';
import ROTM from './TMReadOnly';
import ROBM5 from './BM5ReadOnly';
import ROBM6 from './BM6ReadOnly';
import useLocationService from 'services/useLocationService';
import RODDSM from './DDSMReadOnly';

const ReadOnlySalary = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { getLocationList } = useLocationService();
    //states
    const [selectedRole, setSelectedRole] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [location, setLocation] = useState();
    //services

    //handlers
    const handleChange = (e) => {
        setSelectedLocation(e.target.value);
    };
    const handleGetLocation = () => {
        getLocationList()
            .then((response) => {
                console.log(response);
                setLocation(response.data.locations);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetLocation();
    }, []);

    return (
        <>
            <MainCard>
                <CardHeader
                    avatar={<PaymentsIcon color="primary" />}
                    title="Generate Salary Sheets"
                    sx={{ padding: 0 }}
                    action={
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate('/salaries');
                            }}
                            sx={{ marginRight: 1 }}
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                    }
                />
                <Divider sx={{ marginY: 3 }} />

                <Typography variant="h4">Select parameters to generate read only salaries</Typography>
                <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center" sx={{ marginY: 0.2 }}>
                    <Grid item sm={5} xs={12}>
                        <Select
                            labelId="role-select-label"
                            id="role-select"
                            name="role"
                            size="small"
                            fullWidth
                            //sx={{ width: 300 }}
                            value={selectedRole}
                            onChange={(e) => {
                                setSelectedRole(e.target.value);
                            }}
                        >
                            {roles?.map((role, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        <Typography>{role}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                    {/* <FormControl fullWidth> */}
                    <Grid item sm={3} xs={12}>
                        {/* <FormControl fullWidth>
                        <InputLabel id="location-select-label">Location</InputLabel> */}
                        <Select
                            labelId="location-select-label"
                            id="location-select"
                            size="small"
                            fullWidth
                            // label="Location"
                            name="location"
                            value={selectedLocation}
                            onChange={(e) => {
                                setSelectedLocation(e.target.value);
                            }}
                        >
                            {location?.map((location, index) => {
                                return (
                                    <MenuItem key={index} value={location.id}>
                                        {/* <Stack direction="column"> */}
                                        <Typography>{location.city}</Typography>
                                        {/* </Stack> */}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {/* </FormControl> */}
                    </Grid>
                    {/* </FormControl> */}
                    <Grid item sm={2} xs={12}>
                        <TextField
                            label="Month"
                            size="small"
                            type="number"
                            fullWidth
                            //sx={{ width: 100 }}
                            value={selectedMonth}
                            onChange={(e) => {
                                setSelectedMonth(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField
                            label="Year"
                            size="small"
                            type="number"
                            fullWidth
                            //sx={{ width: 100 }}
                            value={selectedYear}
                            onChange={(e) => {
                                setSelectedYear(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 3 }} />
                {selectedRole == 1 && (
                    <ROSE
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 2 && (
                    <ROGC
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 3 && (
                    <ROGL
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 4 && (
                    <ROTM
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 5 && (
                    <ROABM
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 6 && (
                    <RODBM
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 7 && (
                    <ROBM5
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 8 && (
                    <ROBM6
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
                {selectedRole == 9 && (
                    <RODDSM
                        selectedRole={selectedRole}
                        selectedLocation={selectedLocation}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                    />
                )}
            </MainCard>
        </>
    );
};

export default ReadOnlySalary;
