//*** Decrease size of Mr. textfield */

import React, { useEffect, useState } from 'react';

//MaterialUI Imports
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import SaveIcon from '@mui/icons-material/Save';
import PersonIcon from '@mui/icons-material/Person';

import { useFormik } from 'formik';
import * as Yup from 'yup';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import useEmployeeService from 'services/useEmployeeService';
import { locations, roles } from 'store/constant';
import useLocationService from 'services/useLocationService';

const EmployeeForm = (props) => {
    //constants
    const { onSave, onCancel, selectedEmployee, employees } = props;
    const { addEmployee, updateEmployee } = useEmployeeService();
    const theme = useTheme();
    const { getLocationList } = useLocationService();

    //States
    const [loading, setLoading] = useState();
    const [location, setLocation] = useState();
    //Api call
    const handleGetLocation = () => {
        getLocationList()
            .then((response) => {
                console.log(response);
                setLocation(response.data.locations);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        handleGetLocation();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: selectedEmployee.id,
            salutation: selectedEmployee.salutation,
            firstName: selectedEmployee.firstName,
            middleName: selectedEmployee.middleName,
            lastName: selectedEmployee.lastName,
            role: selectedEmployee.role,
            email: selectedEmployee.email,
            mobileNumber: selectedEmployee.mobileNumber,
            joiningDate:
                selectedEmployee.date != undefined || selectedEmployee.date != null
                    ? new Date(selectedEmployee.date.toString() + 'Z').toISOString().split('T')[0]
                    : new Date().toISOString().split('T')[0],
            reportingTo: selectedEmployee.reportingTo,
            employeeCode: selectedEmployee.employeeCode,
            haveVehicle: selectedEmployee.haveVehicle,
            roomRent: selectedEmployee.roomRent,
            locationId: selectedEmployee.locationId
        },
        validationSchema: Yup.object({
            salutation: Yup.number().required(),
            firstName: Yup.string().max(50, 'Name must be 50 characters or less').required('First Name is required'),
            middleName: Yup.string().max(50, 'Name must be 50 characters or less'),
            lastName: Yup.string().max(50, 'Name must be 50 characters or less').required('Last Name is required'),
            role: Yup.number().required(),
            email: Yup.string().email('must be valid email address').max(100, 'Name must be 100 characters or less').required(),
            mobileNumber: Yup.string()
                .matches(
                    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    'Phone number is not valid'
                )
                .min(10, 'Minimum 10 digits are required')
                .max(10, 'Max 10 digits are allowed')
                .required(),
            reportingTo: Yup.number().required(),
            employeeCode: Yup.string().max(50, 'Must be 50 characters or less'),
            locationId: Yup.number().required()
        }),
        onSubmit: (values) => {
            console.log(values);
            setLoading(true);
            if (selectedEmployee.id != 0) {
                updateEmployee(selectedEmployee.id, values)
                    .then((response) => {
                        if (response.status === 200) {
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            } else {
                // let data = {
                //     salutation: values.salutation,
                //     firstName: values.firstName,
                //     middleName: values.middleName,
                //     lastName: values.lastName,
                //     role: values.role,
                //     email: values.email,
                //     mobileNumber: values.mobileNumber,
                //     joiningDate: values.joiningDate,
                //     reportingTo: values.reportingTo,
                //     employeeCode: values.employeeCode,
                //     haveVehicle: values.haveVehicle,
                //     roomRent: values.roomRent,
                //     location: values.location
                // };
                addEmployee(values)
                    .then((response) => {
                        console.log(response);
                        if (response.status === 200) {
                            setLoading(false);
                            onSave();
                            onCancel();
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        }
    });

    useEffect(() => {
        console.log(employees);
    }, []);
    useEffect(() => {
        if (location && location.length > 0) {
            formik.setValues({
                ...formik.values,
                locationId: location[0].id // Set the locationId to the id of the first location
            });
        }
    }, [location]);
    return (
        <>
            <Grid align="center">
                {selectedEmployee.id === 0 ? (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <PersonIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Add New Employee</Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} sx={{ marginX: 'auto', alignItems: 'center' }}>
                        <PersonIcon fontSize="medium" color="primary" />
                        <Typography variant="h4">Edit Employee Details</Typography>
                    </Stack>
                )}
            </Grid>
            <Divider sx={{ marginY: 3 }} />

            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item sm={3} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="salutation-select-label">Salutation</InputLabel>
                                <Select
                                    labelId="salutation-select-label"
                                    id="salutation-select"
                                    label="Salutation"
                                    name="salutation"
                                    value={formik.values.salutation}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value={0}>Mr.</MenuItem>
                                    <MenuItem value={1}>Mrs.</MenuItem>
                                    <MenuItem value={2}>Miss.</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="firstName"
                                label="First Name*"
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                                fullWidth
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="middleName"
                                label="Middle Name"
                                onChange={formik.handleChange}
                                value={formik.values.middleName}
                                fullWidth
                                error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                                helperText={formik.touched.middleName && formik.errors.middleName}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                name="lastName"
                                label="Last Name*"
                                onChange={formik.handleChange}
                                value={formik.values.lastName}
                                fullWidth
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="employeeCode"
                                label="Employee Code"
                                onChange={formik.handleChange}
                                value={formik.values.employeeCode}
                                fullWidth
                                error={formik.touched.employeeCode && Boolean(formik.errors.employeeCode)}
                                helperText={formik.touched.employeeCode && formik.errors.employeeCode}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="email"
                                label="Email Address *"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                fullWidth
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                name="mobileNumber"
                                label="Mobile Number *"
                                onChange={formik.handleChange}
                                value={formik.values.mobileNumber}
                                fullWidth
                                error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                                helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="role-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    label="Role"
                                    name="role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                >
                                    {roles?.map((role, index) => {
                                        return (
                                            <MenuItem key={index} value={index}>
                                                <Stack direction="column">
                                                    <Typography>{role}</Typography>
                                                </Stack>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                type="date"
                                name="joiningDate"
                                label="Joining Date"
                                onChange={formik.handleChange}
                                value={formik.values.joiningDate}
                                fullWidth
                                error={formik.touched.joiningDate && Boolean(formik.errors.joiningDate)}
                                helperText={formik.touched.joiningDate && formik.errors.joiningDate}
                            />
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="Reporting-select-label">Reporting To</InputLabel>
                                <Select
                                    labelId="reporting-select-label"
                                    id="reporting-select"
                                    label="Reporting To"
                                    name="reportingTo"
                                    value={formik.values.reportingTo}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem key={-1} value={0}>
                                        NA
                                    </MenuItem>
                                    {employees?.map((employee, index) => {
                                        return (
                                            <MenuItem key={index} value={employee.id}>
                                                <Stack direction="column">
                                                    <Typography>
                                                        {employee.firstName} {employee.middleName} {employee.lastName}
                                                    </Typography>
                                                    <Typography sx={{ color: theme.palette.grey[500] }}>{employee.roleDisplay}</Typography>
                                                </Stack>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        {formik.values.role == 2 || formik.values.role == 3 ? (
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    name="roomRent"
                                    label="Room Rent"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.roomRent}
                                    fullWidth
                                    error={formik.touched.roomRent && Boolean(formik.errors.roomRent)}
                                    helperText={formik.touched.roomRent && formik.errors.roomRent}
                                />
                            </Grid>
                        ) : null}
                        {formik.values.role == 1 ? (
                            <Grid item sm={4} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="vehicle-select-label">Have Own Vehicle</InputLabel>
                                    <Select
                                        labelId="vehicle-select-label"
                                        id="vehicle-select"
                                        label="Have Own Vehicle"
                                        name="haveVehicle"
                                        value={formik.values.haveVehicle}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem key={0} value={false}>
                                            NO
                                        </MenuItem>
                                        <MenuItem key={1} value={true}>
                                            Yes
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : null}
                        <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="location-select-label">Location</InputLabel>
                                <Select
                                    labelId="location-select-label"
                                    id="location-select"
                                    label="Location"
                                    name="locationId"
                                    value={formik.values.locationId}
                                    onChange={formik.handleChange}
                                >
                                    {location?.map((location, index) => {
                                        return (
                                            <MenuItem key={index} value={location.id}>
                                                <Stack direction="column">
                                                    <Typography>{location.city}</Typography>
                                                </Stack>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Divider sx={{ marginY: 3 }} />
                    <LoadingButton loading={loading} variant="contained" type="submit" startIcon={<SaveIcon />}>
                        Save
                    </LoadingButton>
                    <Button variant="outlined" sx={{ marginLeft: 2 }} onClick={onCancel}>
                        Cancel
                    </Button>
                </form>
            </Grid>
        </>
    );
};

export default EmployeeForm;
