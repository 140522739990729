import React, { useEffect, useState } from 'react';
//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem,
    Icon
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import RefreshIcon from '@mui/icons-material/Refresh';

//services
import useSalesRecordService from 'services/useSalesRecordService';

//project import
import SalaryDetailComponent from '../SalaryDetailComponent';
import { CollectionsOutlined } from '@mui/icons-material';
import useSalariesService from 'services/useSalariesService';
import useMonthlySaleUnitsService from 'services/useMonthlySaleUnitsService';
import useAmountTransactionService from 'services/useAmountTransactionService';
import { daysInMonth } from 'utils/DateOperations';

const DBMCard = (props) => {
    const { employee, dbmSalarySlabDetails, dbmFiledExpensesDetails, onRefresh, month, year } = props;
    const theme = useTheme();
    const { getAmountTransactionPendingAmount } = useAmountTransactionService();
    const cellWidth = 70;
    //states
    const [submitting, setSubmitting] = useState(false);
    //const [adjustment, setAdjustment] = useState(0);
    const [workDays, setWorkDays] = useState(daysInMonth(new Date().getMonth(), new Date().getFullYear()));
    const [unitsSold, setUnitsSold] = useState(0);
    const [petrolRate, setPetrolRate] = useState(80);
    const [editMode, setEditMode] = useState(true);
    const [saveDBMSalary, setSaveDBMSalary] = useState();
    const [pendingAmountDetails, setPendingAmountDetails] = useState();

    const [dbmSalaryDetails, SetDBMSalaryDetails] = useState({
        fixedSalary: 0,
        units: 0,
        fieldExpenses: 0,
        incentive: 0,
        //adjustment: 0,
        subTotal: 0,
        deduction: 0,
        netAmount: 0,
        remarks: ''
    });
    const [appliedDBMSalarySlab, setAppliedDBMSalarySlab] = useState({
        fixedSalary: 0,
        minUnit: 0,
        maxUnit: 9,
        fieldExpenses: 0,
        incentive: 0
    });

    //Methods
    const handleGetPendingAmount = () => {
        getAmountTransactionPendingAmount(employee.id, month, year)
            .then((response) => {
                if (response.status == 200) {
                    // console.log('AMOUNTS', response.data);
                    setPendingAmountDetails(response.data);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //use effects
    useEffect(() => {
        handleGetPendingAmount();
    }, [employee.id, month, year]);

    //services
    //const { getUnitsSold } = useSalesRecordService();
    const { getMonthlySaleUnits } = useMonthlySaleUnitsService();
    const { addSalaries } = useSalariesService();

    //handlers
    //calculated values
    const handleCalculate = () => {
        const perDaySalary = appliedDBMSalarySlab.fixedSalary / daysInMonth(month, year);
        // Calculate the basic salary based on the number of working days
        const basicSalary = perDaySalary * workDays;
        let calcAmount = appliedDBMSalarySlab.fieldExpenses * unitsSold;
        let calcIncentive = appliedDBMSalarySlab.incentive;
        let calcSubTotal = basicSalary + calcAmount + calcIncentive;
        let calcDeduction = pendingAmountDetails?.amount || 0;
        let calcNetAmount =
            //appliedDBMSalarySlab.fixedSalary + calcAmount + calcIncentive
            calcSubTotal - calcDeduction;

        SetDBMSalaryDetails((prev) => ({
            ...prev,
            fixedSalary: basicSalary,
            units: unitsSold,
            fieldExpenses: calcAmount,
            incentive: calcIncentive,
            //adjustment: adjustment,
            subTotal: calcSubTotal,
            deduction: calcDeduction,
            netAmount: calcNetAmount
        }));
    };

    const handleSetSalarySlabData = () => {
        console.log(dbmFiledExpensesDetails);
        let selectedData = dbmFiledExpensesDetails?.filter((item) => {
            return unitsSold >= item.unitMin && unitsSold <= item.unitMax;
        });

        console.log(selectedData);
        if (selectedData.length > 0) {
            let data = {
                fixedSalary: dbmSalarySlabDetails.fixedSalary,
                minUnit: selectedData[0].unitMin,
                maxUnit: selectedData[0].unitMax,
                fieldExpenses: selectedData[0].fieldExpenses,
                incentive: selectedData[0].incentive
            };
            setAppliedDBMSalarySlab(data);
            handleCalculate();
        }
    };

    const handelGetSalesData = () => {
        getMonthlySaleUnits(employee.id, month, year).then((response) => {
            if (response.status == 200) {
                setUnitsSold(response.data.unit);
            }
        });
    };

    const dbmSaveSalary = () => {
        let data = {
            userId: employee.id,
            month: month,
            year: year,
            unitSold: unitsSold,
            workDays: workDays,
            //adjustment: adjustment,
            petrolRate: petrolRate,
            fixedSalary: dbmSalaryDetails.fixedSalary,
            fieldExpences: dbmSalaryDetails.fieldExpenses,
            incentive: dbmSalaryDetails.incentive,
            pri: dbmSalaryDetails.pri,
            da: dbmSalaryDetails.da,
            ta: dbmSalaryDetails.ta,
            petrol: dbmSalaryDetails.petrol,
            groupIncentive: dbmSalaryDetails.groupIncentive,
            subTotal: dbmSalaryDetails.subTotal,
            deduction: dbmSalaryDetails.deduction,
            netSalary: dbmSalaryDetails.netAmount,
            remark: ''
        };
        console.log('dataapi', data);
        addSalaries(data)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    //setSaveGCSalary(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleCalculate();
        //dbmSaveSalary();
    }, [petrolRate, workDays, unitsSold, dbmSalarySlabDetails, dbmFiledExpensesDetails, employee.id]);

    useEffect(() => {
        handelGetSalesData();
    }, [month, year, employee.id]);

    useEffect(() => {
        if (dbmSalarySlabDetails && dbmFiledExpensesDetails) {
            handleSetSalarySlabData();
        }
    }, [dbmSalarySlabDetails, dbmFiledExpensesDetails, unitsSold]);

    return (
        <Card variant="outlined" sx={{ padding: 2, backgroundColor: theme.palette.grey[100] }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        {employee.employeeCode}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.secondary.main}>
                        {employee.salutationDisplay} {employee.firstName} {employee.middleName} {employee.lastName}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        +91-{employee.mobileNumber}
                    </Typography>
                    <Typography variant="h5" color={theme.palette.grey[500]}>
                        |
                    </Typography>
                    {employee.role == 1 && employee.haveVehicle && (
                        <TwoWheelerIcon fontSize="small" sx={{ color: theme.palette.grey[500] }} />
                    )}
                </Stack>
                {editMode && (
                    <Button
                        size="small"
                        onClick={() => {
                            handleGetPendingAmount();
                            handelGetSalesData();
                            handleCalculate();
                        }}
                    >
                        <RefreshIcon fontSize="small" />
                    </Button>
                )}
            </Stack>
            <Divider sx={{ marginY: 2 }} />
            {editMode ? (
                <Stack direction="row" spacing={1}>
                    <TextField label="Units Sold" fullWidth size="small" value={unitsSold} />
                    <TextField
                        label="Work Days"
                        fullWidth
                        size="small"
                        value={workDays}
                        onChange={(e) => {
                            setWorkDays(e.target.value);
                        }}
                    />

                    <TextField
                        label="Petrol Rate"
                        size="small"
                        fullWidth
                        value={petrolRate}
                        onChange={(e) => {
                            setPetrolRate(e.target.value);
                        }}
                    />
                </Stack>
            ) : (
                <Stack direction="row" spacing={3}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Units Sold
                        </Typography>
                        <Typography>{unitsSold}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Work Days
                        </Typography>
                        <Typography>{workDays}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h5" color={theme.palette.grey[500]}>
                            Petrol Rate
                        </Typography>
                        <Typography>₹ {petrolRate}</Typography>
                    </Stack>
                </Stack>
            )}
            <Divider sx={{ marginY: 2 }} />
            <Stack spacing={1} sx={{ marginX: 2 }}>
                <SalaryDetailComponent
                    title="Fixed Salary"
                    description={`₹ ${dbmSalaryDetails.fixedSalary.toFixed(2)} (From Table)`}
                    value={dbmSalaryDetails.fixedSalary}
                />
                <SalaryDetailComponent
                    title="Filed Expenses"
                    description={`${unitsSold} Units x ₹ ${appliedDBMSalarySlab.fieldExpenses} (From Table)`}
                    value={dbmSalaryDetails.fieldExpenses}
                />
                <SalaryDetailComponent
                    title="Incentive"
                    description={`₹ ${appliedDBMSalarySlab.incentive} (From Table)`}
                    value={dbmSalaryDetails.incentive}
                />

                <Divider />
                <SalaryDetailComponent title="Sub Total" value={dbmSalaryDetails.subTotal} />
                <Divider />
                <SalaryDetailComponent
                    title="Deduction"
                    description={` ₹ ${dbmSalaryDetails.deduction}`}
                    value={dbmSalaryDetails.deduction}
                />
                <Divider />
                <SalaryDetailComponent title="Net Salary" value={dbmSalaryDetails.netAmount} />
                <Divider />
            </Stack>

            {editMode ? (
                <TextField
                    label="Remarks"
                    fullWidth
                    size="small"
                    value={dbmSalaryDetails.remarks}
                    sx={{ marginTop: 2 }}
                    multiline
                    onChange={(e) => {
                        SetDBMSalaryDetails((prev) => ({
                            ...prev,
                            remarks: e.target.value
                        }));
                    }}
                />
            ) : (
                <Stack direction="column" spacing={1} sx={{ marginX: 2, marginTop: 2 }}>
                    <Typography variant="h5">Remarks</Typography>
                    <Typography>{dbmSalaryDetails.remarks == '' ? '---' : dbmSalaryDetails.remarks}</Typography>
                </Stack>
            )}

            <Divider sx={{ marginY: 2 }} />

            <Button
                variant="outlined"
                onClick={() => {
                    handleCalculate();
                }}
            >
                Calculate
            </Button>
            <LoadingButton
                loading={submitting}
                variant="contained"
                // value={saveDBMSalary}
                onClick={() => {
                    //handleCalculate();
                    dbmSaveSalary();
                }}
                sx={{ marginLeft: 2 }}
            >
                Save
            </LoadingButton>
        </Card>
    );
};

export default DBMCard;
