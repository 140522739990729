import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useDBMSalarySlabsService = () => {
    const getDBMSalarySlab = async () => {
        try {
            const response = await axiosAuth.get(`/api/DBMSalarySlabs`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getDBMSalarySlabById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/DBMSalarySlabs/${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateDBMSalarySlab = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/DBMSalarySlabs?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getDBMSalarySlab, getDBMSalarySlabById, updateDBMSalarySlab };
};

export default useDBMSalarySlabsService;
