import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useLocationService = () => {
    const getLocationList = async () => {
        try {
            const response = await axiosAuth.get(`/api/Location`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getLocationById = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Location/id?id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addLocation = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Location`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateLocation = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/Location?id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const deleteLocation = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/Location?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getLocationList, getLocationById, addLocation, updateLocation, deleteLocation };
};

export default useLocationService;
