import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useBM5FieldExpenses = () => {
    const getBM5FieldExpencesByBM5SalarySlabId = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/BM5FieldExpences/by-bm5-salaryslab-id?bm5SalarySlabId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addBM5FieldExpenses = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/BM5FieldExpences`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const updateBM5FieldExpenses = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/BM5FieldExpences?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteBM5FieldExpenses = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/BM5FieldExpences/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getBM5FieldExpencesByBM5SalarySlabId, addBM5FieldExpenses, updateBM5FieldExpenses, softDeleteBM5FieldExpenses };
};

export default useBM5FieldExpenses;
