import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

//MaterialUI Imports
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
    CardHeader,
    InputLabel,
    Card,
    CardContent,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';

//icon imports
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FunctionsIcon from '@mui/icons-material/Functions';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

//services imports
import useEmployeeService from 'services/useEmployeeService';
import useSESalarySlabsService from 'services/useSESalarySlabsService';
import useSEFieldExpencessService from 'services/useSEFieldExpencessService';

//project imports
import { roles } from 'store/constant';
import { LoadingButton } from '@mui/lab';
//import SECard from './SECard';
import ROSESalarySheet from './ROSESalarySheet';
import useSalariesService from 'services/useSalariesService';

//third pary imports

const ROSE = (props) => {
    const { selectedRole, selectedLocation, selectedMonth, selectedYear } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    //states
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState();
    const [salarySlabDetails, setSalarySlabDetails] = useState();
    const [filedExpensesDetails, setFiledExpensesDetails] = useState();
    const [salaryReadOnly, setSalaryReadOnly] = useState();

    //services
    const { getEmployees } = useEmployeeService();
    const { getSESalarySlab } = useSESalarySlabsService();
    const { getSEFieldExpences } = useSEFieldExpencessService();
    const { getAllSalaries } = useSalariesService();

    //handlers
    const handleGetEmployees = () => {
        getEmployees('', selectedRole, selectedLocation, 0, 100000)
            .then((response) => {
                if (response.status == 200) {
                    setEmployees(response.data.users);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalaryReadOnly = () => {
        getAllSalaries(selectedRole, selectedLocation, selectedMonth, selectedYear)
            .then((response) => {
                console.log('response', selectedRole, selectedLocation, selectedMonth, selectedYear);
                if (response.status == 200) {
                    setSalaryReadOnly(response.data.salaries);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleGetSalarySlabs = () => {
        getSESalarySlab()
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.list.length > 0) {
                        setSalarySlabDetails(response.data.list[0]);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleGetFieldExpenses = () => {
        getSEFieldExpences(salarySlabDetails.id)
            .then((response) => {
                if (response.status == 200) {
                    setFiledExpensesDetails(response.data.list);
                } else {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //use effects
    useEffect(() => {
        handleGetEmployees();
        handleGetSalarySlabs();
    }, [selectedRole, selectedLocation]);

    useEffect(() => {
        if (salarySlabDetails) {
            handleGetFieldExpenses();
        }
    }, [salarySlabDetails]);
    useEffect(() => {
        handleGetSalaryReadOnly();
    }, [selectedRole, selectedLocation, selectedMonth, selectedYear]);
    console.log('read only', salaryReadOnly);
    return (
        <Box>
            <Grid container spacing={2}>
                <>
                    {/* {employees.map((employee, index) => { */}

                    <Grid item xs={12} md={12}>
                        <ROSESalarySheet
                            employee={employees}
                            salarySlabDetails={salarySlabDetails}
                            filedExpensesDetails={filedExpensesDetails}
                            salaryData={salaryReadOnly}
                            onRefresh={() => {
                                handleGetSalarySlabs();
                                handleGetFieldExpenses();
                            }}
                            location={selectedLocation}
                            month={selectedMonth}
                            year={selectedYear}
                        />
                    </Grid>

                    {/* })} */}
                </>
            </Grid>
        </Box>
    );
};

export default ROSE;
