import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
//Material Imports
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

//Icon Imports
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';

//Project Imports
import MainCard from 'ui-component/cards/MainCard';
import NoResults from 'assets/images/noresults.png';
import BM5FieldExpenses from './BM5FieldExpenses';
import useBM5SalarySlabs from 'services/useBM5SalarySlabsService';
import useBM5FieldExpenses from 'services/useBM5FieldExpensesService';

const BM5SalarySlab = () => {
    //Constants
    const theme = useTheme();
    const { getBM5SalarySlab, updateBM5SalarySlab } = useBM5SalarySlabs();
    const { getBM5FieldExpencesByBM5SalarySlabId, softDeleteBM5FieldExpenses } = useBM5FieldExpenses();
    const [searchParams, setSearchParams] = useSearchParams();

    //States
    const [loading, setLoading] = useState(false);
    const [loadingBM5SalaryUpdate, setLoadingBM5SalaryUpdate] = useState(false);
    const [bm5SalarySlabDetails, setBM5SalarySlabDetails] = useState([]);
    const [bm5FieldExpensesDetails, setBM5FieldExpensesDetails] = useState([]);

    const [selectedBM5FieldExpenses, setSelectedBM5FieldExpenses] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [isError, setIsError] = useState();

    const [bm5FixedSalary, setBM5FixedSalary] = useState('');

    const [bm5SalarySlabId, setBM5SalarySlabId] = useState(0);

    // Methods

    const handleCancel = () => {
        setOpenForm(false);
    };

    const handleSave = () => {
        getBM5FieldExpensesList();
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    const getBM5SalarySlabList = () => {
        getBM5SalarySlab()
            .then((response) => {
                console.log(response);
                setBM5SalarySlabDetails(response.data.bM5SalarySlabs);
                setBM5FixedSalary(response.data.bM5SalarySlabs[0].fixedSalary);
                setBM5SalarySlabId(response.data.bM5SalarySlabs[0].id);
            })
            .catch((error) => setIsError(error.message));
    };

    //BM-5 Field Expenses
    const getBM5FieldExpensesList = () => {
        setBM5FieldExpensesDetails(undefined);
        getBM5FieldExpencesByBM5SalarySlabId(bm5SalarySlabId)
            .then((response) => {
                console.log(response);
                setBM5FieldExpensesDetails(response.data.bM5FieldExpences);
            })
            .catch((error) => setIsError(error.message));
    };

    const handleDelete = () => {
        setLoading(true);
        softDeleteBM5FieldExpenses(selectedBM5FieldExpenses.id)
            .then((response) => {
                getBM5FieldExpensesList();
                setOpenToast(true);
                setOpenDeleteDialog(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setOpenDeleteDialog(false);
            });
    };

    const handleUpdate = () => {
        setLoadingBM5SalaryUpdate(true);
        let data = {
            fixedSalary: bm5FixedSalary
        };
        updateBM5SalarySlab(bm5SalarySlabDetails[0].id, data).then((response) => {
            setLoadingBM5SalaryUpdate(false);
            console.log('UPDATE CALL', response);
        });
    };

    //UseEffects
    useEffect(() => {
        getBM5SalarySlabList();
    }, []);
    useEffect(() => {
        getBM5FieldExpensesList();
    }, [bm5SalarySlabId]);

    return (
        <>
            <MainCard>
                <CardHeader title="BM-5 Salary Slabs" avatar={<PaymentsIcon color="primary" />} sx={{ padding: 0 }} />
                <Divider sx={{ marginY: 3 }} />
                <Grid container spacing={2} direction="row" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Grid item sm={2} xs={12}>
                        <Typography variant="h5">Fixed Salary :</Typography>
                    </Grid>
                    {bm5FixedSalary != undefined || bm5FixedSalary != null ? (
                        <Grid item sm={4} xs={12}>
                            <TextField
                                id="Fixed Salary"
                                type="number"
                                name="bm5FixedSalary"
                                label="Fixed Salary"
                                variant="outlined"
                                value={bm5FixedSalary}
                                fullWidth
                                onChange={(event) => {
                                    setBM5FixedSalary(event.target.value);
                                    //console.log(abmFixeSalary);
                                }}
                            ></TextField>
                        </Grid>
                    ) : null}

                    <Grid item sm={4} xs={12}>
                        <LoadingButton
                            loading={loadingBM5SalaryUpdate}
                            onClick={() => {
                                handleUpdate();
                            }}
                            variant="contained"
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 2 }} />

                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item sm={6} xs={6} align="left">
                        <Typography variant="h5">BM5 Field Expenses</Typography>
                    </Grid>
                    <Grid align="right" sm={6} item>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddIcon />}
                            align="right"
                            onClick={() => {
                                setSelectedBM5FieldExpenses({
                                    id: 0,
                                    bM5SalarySlabId: 0,
                                    unitMin: 0,
                                    unitMax: 0,
                                    amount: 0,
                                    incentive: 0
                                });
                                setOpenForm(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>

                    <Grid item sm={12} xs={12}>
                        {bm5FieldExpensesDetails != undefined || bm5FieldExpensesDetails != null ? (
                            <>
                                {bm5FieldExpensesDetails?.length === 0 ? (
                                    <Grid align="center" sx={{ mt: 5, mb: 5 }}>
                                        <img src={NoResults} alt="No Result" width="150px" />
                                        <Typography variant="h4" fontWeight={100}>
                                            There are no BM-5 field expenses details found
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ display: { xs: 'none', sm: 'block', md: 'block', xl: 'block' } }}
                                        >
                                            <TableContainer sx={{ maxWidth: 500 }}>
                                                <Table
                                                    sx={{
                                                        border: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: 'divider'
                                                    }}
                                                >
                                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                        <TableCell sx={{ width: 80 }}>
                                                            <Typography variant="h5">Sr.No.</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell>
                                                            <Typography variant="h5">Units</Typography>{' '}
                                                        </TableCell>

                                                        <TableCell sx={{ width: 100 }}>
                                                            <Typography variant="h5">Amount</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 100 }}>
                                                            <Typography variant="h5">Incentive</Typography>
                                                        </TableCell>

                                                        <TableCell sx={{ width: 110 }}></TableCell>
                                                    </TableHead>

                                                    <TableBody>
                                                        {bm5FieldExpensesDetails?.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {index + 1}
                                                                </TableCell>
                                                                {row.unitMax > 0 ? (
                                                                    <TableCell align="left">
                                                                        {row.unitMin} - {row.unitMax}
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell align="left">Above {row.unitMin}</TableCell>
                                                                )}

                                                                <TableCell align="left">{row.amount}</TableCell>
                                                                <TableCell align="left">{row.incentive}</TableCell>

                                                                <TableCell align="right">
                                                                    <Tooltip title="Edit BM-5 field expenses details">
                                                                        <IconButton
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedBM5FieldExpenses(row);
                                                                                setOpenForm(true);
                                                                            }}
                                                                        >
                                                                            <EditIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete BM-5 Fixed Expenses">
                                                                        <IconButton
                                                                            color="error"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setSelectedBM5FieldExpenses(row);
                                                                                setOpenDeleteDialog(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        {bm5FieldExpensesDetails.map((row, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{ display: { xs: 'block', sm: 'none', md: 'none', xl: 'none' } }}
                                            >
                                                <Card sx={{ p: 2 }} variant="outlined" key={index}>
                                                    <Stack justifyContent="space-between" direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="h5">Unit:</Typography>
                                                        <Typography>
                                                            {row.unitMin} - {row.unitMax}
                                                        </Typography>
                                                    </Stack>

                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Field Expenses:</Typography>
                                                        <Typography>{row.amount}</Typography>
                                                    </Stack>
                                                    <Stack justifyContent="space-between" direction="row" sx={{ mt: 2 }}>
                                                        <Typography variant="h5">Incentive:</Typography>
                                                        <Typography>{row.incentive}</Typography>
                                                    </Stack>
                                                    <Divider sx={{ mt: 1 }} />
                                                    <Stack justifyContent="flex-end" direction="row" alignItems="right" sx={{ mt: 2 }}>
                                                        <Tooltip title="Edit BM-5 field expenses details">
                                                            <IconButton
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedBM5FieldExpenses(row);
                                                                    setOpenForm(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete BM-5 Field Expenses">
                                                            <IconButton
                                                                color="error"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedBM5FieldExpenses(row);
                                                                    setOpenDeleteDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                                <Stack direction="column" justifyContent="Center" alignItems="center" spacing={2} sx={{ marginY: 3 }}>
                                    <Typography variant="h4" textAlign="center">
                                        Getting List...
                                    </Typography>
                                    <CircularProgress />
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* }
                /> */}
            </MainCard>
            <Dialog open={openForm} onClose={handleCancel} fullWidth maxWidth="sm">
                <DialogContent>
                    <BM5FieldExpenses onSave={handleSave} onCancel={handleCancel} selectedBM5FieldExpenses={selectedBM5FieldExpenses} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h4" color={theme.palette.secondary.dark}>
                        Delete BM-5 Field Expenses Details
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h5" color={theme.palette.error}>
                        Are you sure you want to delete this BM-5 field expenses details ?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            handleDelete();
                        }}
                        variant="contained"
                    >
                        Yes
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                        variant="outlined"
                    >
                        No
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert
                        onClose={handleCloseToast}
                        severity="success"
                        sx={{ width: '100%', backgroundColor: theme.palette.success.main }}
                    >
                        <Typography sx={{ color: theme.palette.grey[50] }}> BM-5 field expenses deleted succesfully!!</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default BM5SalarySlab;
