import React from 'react';
import { axiosAuth } from './apiBase';

import { handleResponse, handleError } from './response';

const useGCGroupIncentivesService = () => {
    const getGCGroupIncentives = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/GCGroupIncentives/by-gc-salaryslab-id?gcSalaryslabId=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const addGCGroupIncentives = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/GCGroupIncentives`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const updateGCGroupIncentives = async (id, values) => {
        try {
            const response = await axiosAuth.put(`/api/GCGroupIncentives?Id=${id}`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const softDeleteGCGroupIncentives = async (id) => {
        try {
            const response = await axiosAuth.delete(`/api/GCGroupIncentives/soft-delete?Id=${id}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { getGCGroupIncentives, addGCGroupIncentives, updateGCGroupIncentives, softDeleteGCGroupIncentives };
};

export default useGCGroupIncentivesService;
