// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const borderRadius = 3;

export const roles = [
    'Admin',
    'Sales Executive (SE)',
    'Group Coordinator (GC)',
    'Group Leader (GL)',
    'Team Manager (TM)',
    'Assistant Branch Manager (ABM)',
    'Divisional Branch Manager (DBM)',
    'Branch Manager (BM-5)',
    'Branch Manager (BM-6)',
    'NavBharat Company'
];
export const locations = [
    'Baramati',
    'Kolhapur',
    'Pernephata',
    'Shikarapur',
    'Sangamner',
    'Varwand',
    'Kedgaon',
    'Daund',
    'Pargaon',
    'Satara',
    'Ugar',
    'Yavath',
    'Loni',
    'Gothe'
];
